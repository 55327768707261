import React, { useEffect, useState, useRef  } from 'react';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import KakaoLogin from 'react-kakao-login';
import Button from '../components/Button'
import { ScanCommand } from '@aws-sdk/lib-dynamodb';

import Papa from 'papaparse'; // 엑셀 파싱 모듈
import AWS from 'aws-sdk'; // aws 전용 
import { saveAs } from 'file-saver'; // 파일 저장 모듈
import EventTracker from '../components/EventTracker';
import ProgressBarScrap from '../components/ProgressBarScrap'; // 크롤링 진척 확인용
import Swal from 'sweetalert2';

// import { dynamodb } from '../config';
import {rdsread, API_S3_GETOBJ1 } from '../config';
import InfoTooltip from '../components/InfoTooltip'; // 데이터 다운로드 받을 수 있는 곳 안내용

const mainColor = 'rgb(70, 100, 166)';
const Mypage = () => {

    // let filteredData;
    // 퍼널 분석용 페이지명
    const pageName = "mypage";

    const [profileID, setProfileID] = useState('');
    const history = useHistory();  // useHistory 훅 초기화
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const { Kakao } = window;
    const [logoutTracked, setLogoutTracked] = useState(false);

    const [dataCollectList, setDataCollectList] = useState([]);

    const dataCollectHeader = [
        "수집/분석 요청일", // request_date
        // "고객번호", // 삭제예정
        // "거래번호", // transaction_no
        "채널ID", // service_code (유튜브 or 플레이스토어)
        "수집/분석DataID",
        "건수", // request_cnt
        "처리상태", // complete_code (0 : 진행중,  1 : 완료)
        "링크",    // 유튜브 or 플레이스토어 원형에 맞게
        "비고" // 다운로드 버튼용
    ]

    const [dataCollectHeaderWidths, setDataCollectHeaderWidths] = useState([]);
    const [paymentListHeaderWidths, setPaymentListHeaderWidths] = useState([]);

    const [dataCollected, setDataCollected] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    // const s3 = new AWS.S3();

    const paymentHeader = [
        "결제일",  // payment_date
        // "고객번호", // 삭제예정
        "거래번호", // transaction_no
        "상품종류", // service_code / 크롤링 Or 시각화
        "수집채널",  // 구글플레이스토어 Or 유튜브
        "수집건수", // request_cnt
        "결제금액", // payment_amount
        "링크" // 링크
    ];

    const logoutEventParams = {
        pageName: "logout",
        pageCode: "p0002",
        eventName: "logout",
        eventCode: "e0002",
        transactionNo: "",
    };

    const getMaxTextWidth = (texts) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        let maxWidth = 0;

        texts.forEach((text) => {
            const width = context.measureText(text).width;
            if (width > maxWidth) {
                maxWidth = width + 20;
            }
        });

        return maxWidth;
    };

    const showTableList = ['tb_user_request', 'tb_payment'];

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        if (!storedProfileID & !logoutTracked) history.push('/login');
        console.log(`현재 프로필 ID(로컬스토리지에서 가져온) : ${storedProfileID}`)

        // 데이터 수집 이력 테이블 헤더 컬럼 길이 세팅
        const dataCollectedWidths_ = dataCollectHeader.map(header => getMaxTextWidth([header]));
        setDataCollectHeaderWidths(dataCollectedWidths_);

        const paymentListWidths_ = paymentHeader.map(header => getMaxTextWidth([header]));
        setPaymentListHeaderWidths(paymentListWidths_);

        // showTableList.map((tableName) => {
        //     fetchUserData(tableName);
        // })
    }, [logoutTracked]);

    useEffect(() => {
        if (profileID) {
            console.log(`현재 프로필 ID: ${profileID}`);
            showTableList.forEach((tableName) => {
                fetchUserData(tableName);
            });
        }
    }, [profileID]); // profileID가 변경될 때마다 호출


    // 쿼리해서 해당 사용자만 가져오기.
    const fetchUserData = async (tableName) => {
        try {
        // Lambda 함수 호출
        console.log(`profileID_Mypage : ${profileID}`);

        //RDS
        const response = await axios.post(rdsread, {
            table_name: tableName,
            ProfileID: profileID  // 쿼리에서 사용할 ProfileID
        });

        // 응답에서 데이터 추출
        const responseData = response.data.body;
        let data;
        if (typeof responseData === 'string') {
            data = JSON.parse(responseData);
        } else {
            data = responseData;
        }
        console.log(`data : ${data}`);
        console.log(`tableName : ${tableName}`);

        // 테이블 이름에 따라 데이터를 상태에 저장
        if (tableName === showTableList[0]) {
            setDataCollected(data);
        } else if (tableName === showTableList[1]) {
            setPaymentList(data);
        }

        console.log(`${tableName} 데이터 가져오기 성공:`, data);
        } catch (error) {
        console.error(`${tableName} 데이터 가져오기 오류:`, error);
        }
    };

    // 로그아웃 버튼 클릭 시 호출 함수
    const handleLogout = () => {
        console.log('handleLogout 실행');

        if (Kakao.Auth.getAccessToken()) {
            console.log(
                '카카오 인증 액세스 토큰이 존재합니다.',
                Kakao.Auth.getAccessToken(),
            );

            Kakao.Auth.logout(() => {
                console.log('로그아웃 되었습니다.', Kakao.Auth.getAccessToken());
                Swal.fire({
                    icon: 'success',
                    title: '로그아웃되었습니다',
                    // text: `* 파일명 : ${selectedFile.name}`,
                    confirmButtonText: '확인',
                });
                setProfileID('');
                localStorage.clear();
                setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
                history.push('/'); // 로그아웃 후에 페이지 이동
            });
        } else {
            console.log('카카오 인증 액세스 토큰이 존재하지 않습니다.');
            setProfileID('');
            localStorage.clear();
            setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
            history.push('/'); // 액세스 토큰이 없을 때 페이지 이동
        }
    };

    // 회원탈퇴 버튼 클릭 시 호출 함수
    const handleDeleteAccount = () => {
        Swal.fire({
            title: '회원탈퇴',
            text: '정말로 회원탈퇴를 하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '탈퇴',
            cancelButtonText: '취소'
        }).then((result) => {
            if (result.isConfirmed) {
                // 사용자가 '탈퇴' 버튼을 클릭한 경우에만 회원탈퇴 진행
                if (Kakao.Auth.getAccessToken()) {
                    console.log(
                        '카카오 인증 액세스 토큰이 존재합니다.',
                        Kakao.Auth.getAccessToken(),
                    );

                    Kakao.API.request({
                        url: '/v1/user/unlink',
                        success: function(response) {
                            console.log('회원탈퇴(연결 끊기) 성공', response);
                            Swal.fire({
                                icon: 'success',
                                title: '회원탈퇴가 완료되었습니다',
                                confirmButtonText: '확인',
                            });
                            setProfileID('');
                            localStorage.clear();
                            setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
                            history.push('/'); // 탈퇴 후에 페이지 이동
                        },
                        fail: function(error) {
                            console.error('회원탈퇴(연결 끊기) 실패', error);
                            Swal.fire({
                                icon: 'error',
                                title: '회원탈퇴에 실패했습니다',
                                text: '다시 시도해주세요.',
                                confirmButtonText: '확인',
                            });
                        },
                    });
                } else {
                    console.log('카카오 인증 액세스 토큰이 존재하지 않습니다.');
                    setProfileID('');
                    localStorage.clear();
                    setLogoutTracked(true); // 로그아웃 트래커를 트리거합니다.
                    history.push('/'); // 액세스 토큰이 없을 때 페이지 이동
                }
            }
        });
    };
    


    const dataCollectedTextReplace = (text, column, channel) => {

        if (column === 'service_code') {
            if (text === 'cr_0001') return '구글 플레이 스토어';
            else if (text === 'cr_0002') return '유튜브';
            else if (text.includes('an_')) return '데이터 시각화';

        } else if (column === 'complete_code') {
            if (text === 0) return '📀 진행중';
            else if (text === 1) return '✅ 완료';
            else return text;

        } else if (column == '링크') {
            if (channel === 'cr_0001') return `https://play.google.com/store/apps/details?id=${text}`;
            else if (channel === 'cr_0002') return `https://www.youtube.com/watch?v=${text}`;
            else if (channel.includes('an')) return '-';

        } else if (column == '상품종류') {
            if (text.includes('cr')) return "Data 수집";
            else if (text.includes('an')) return "Data 분석";

        } else {
            return;
        }

    };

    const formatDate = (inputDateString) => {
        const date = new Date(inputDateString);
        // console.log('date',{inputDateString});

        // 월과 일이 한 자리 수일 경우 앞에 0을 붙이기 위한 함수
        const padZero = (num) => (num < 10 ? '0' + num : num);

        const year = date.getFullYear();
        const month = padZero(date.getMonth() + 1);  // getMonth()는 0부터 시작하므로 +1
        const day = padZero(date.getDate());
        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());
        const seconds = padZero(date.getSeconds());

        return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
    };

    //Filesaver -> link 형태로 변경
    //s3.object 에서 lambda 접근으로 변경
    const downloadFile = async (serviceCode, productCode, request_cnt) => {
        try {
            const response = await axios.post(API_S3_GETOBJ1, {
                serviceCode,
                productCode,
                request_cnt
            }, {
                responseType: 'json'  // 서버에서 JSON 응답을 받기
            });
    
            // 서버 응답이 오류 메시지인지 확인
            if (response.headers['content-type'].includes('application/json')) {
                console.error('오류 응답:', response.data);
                return;
            }
    
            const { body, isBase64Encoded } = response.data;
    
            // base64 문자열을 UTF-8로 디코딩
            const fileContent = isBase64Encoded ? atob(body) : body;
    
            // 파일 콘텐츠로 Blob 생성 (UTF-8 with BOM)
            const blob = new Blob([fileContent], { type: 'text/csv; charset=utf-8' });
            const downloadFilename = `${serviceCode}_${productCode}_${request_cnt}.csv`;
    
            // 파일 다운로드 처리
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url); // 메모리 해제
        } catch (error) {
            console.error(`파일 다운로드 오류: ${error}`);
        }
    };

    const handleLink = (item) => {
        console.log(`item : ${item}`);
        return (
            item.service_code.includes("cr")
                ? <a href={dataCollectedTextReplace(item.product_code, "링크", item.service_code)} target='_blank' style={{ color: 'blue', textDecoration: 'underline' }}>링크</a>
                : <p>-</p>
        )

    }





    //Data 수집/분석 이력 = showDataCollectList = TableRow + DataTable
    const TableRow = ({ item, downloadFile }) => {
        const [progress, setProgress] = useState(0);
        const [timeDifference, setTimeDifference] = useState(0); // timeDifference 상태 추가
    
        useEffect(() => {
            if (item.complete_code == 0) {
                const updateProgress = () => {
                    // request_date를 한국 표준시로 파싱
                    const requestDateString = item.request_date.split('.')[0] + '+09:00'; // 한국 시간대 추가
                    const requestDate = new Date(requestDateString);
    
                    // 현재 시간도 한국 표준시로 맞춤
                    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
    
                    const diff = (now - requestDate) / 1000 / 60; // minutes
                    setTimeDifference(diff); // 상태 업데이트
    
                    // 10분 기준으로 최대 99%까지 진행률 계산
                    const progressPercentage = Math.min((diff / 10) * 100, 99);
    
                    // 로그: 진행률 계산 확인
                    console.log('Progress Percentage:', progressPercentage);
    
                    setProgress(progressPercentage);
                };
    
                updateProgress(); // 초기 호출
                const intervalId = setInterval(() => {
                    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }));
                    const requestDateString = item.request_date.split('.')[0] + '+09:00'; // 한국 시간대 추가
                    const requestDate = new Date(requestDateString);
                    const diff = (now - requestDate) / 1000 / 60; // minutes
                    console.log('diff:',diff);

                    if (diff < 15) {
                        updateProgress(); // 10초마다 업데이트
                        console.log('Progress updated');
                    } else {
                        // diff가 15분 이상이면 인터벌 클리어
                        clearInterval(intervalId);
                    }
                }, 10000); // 10초마다 업데이트
    
                return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 클리어
            }
        }, [item.request_date, item.complete_code]);
    
        return (
            <tr>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{formatDate(item.request_date)}</td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{dataCollectedTextReplace(item.service_code, "service_code", "")}</td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.product_code}</td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{new Intl.NumberFormat('en-US').format(item.request_cnt)}</td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    {item.complete_code == 0 ? (
                        timeDifference >= 15 ? (
                            <div style={{ color: 'red' }}>❌실패</div>
                        ) : (
                            <div style={{
                                position: 'relative',
                                width: '100%',
                                backgroundColor: '#b3afaf',
                                height: '20px', // 바 높이 조절
                                borderRadius: '5px', // 모서리 둥글게
                                overflow: 'hidden' // 진행 바가 부모 컨테이너를 넘지 않도록
                            }}>
                                <div style={{
                                    width: `${progress}%`, // 진행률에 따른 너비
                                    height: '100%',
                                    backgroundColor: '#76c7c0',
                                    position: 'absolute', // 절대 위치
                                    left: 0,
                                    top: 0,
                                }}></div>
                                <div style={{
                                    position: 'absolute', // 전체 바의 중앙에 고정
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)', // 중앙 정렬
                                    color: 'white', // 텍스트 색상
                                    // fontWeight: 'bold',
                                    fontSize: '14px' // 글자 크기 조절
                                }}>
                                    {Math.round(progress)}%
                                </div>
                            </div>
                        )
                    ) : (
                        "✅수집완료"
                    )}
                </td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    {handleLink(item)}
                </td>
                <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                    <button
                        style={{
                            backgroundColor: 'white',
                            border: 'None',
                            cursor: 'pointer',
                            color: 'blue',
                            padding: '5px'
                        }}
                        onClick={() => { downloadFile(item.service_code, item.product_code, item.request_cnt) }}
                    >
                        Download
                    </button>
                </td>
            </tr>
        );
    };

    // const DataTable = ({ dataCollected, dataCollectHeader, dataCollectHeaderWidths, profileID, downloadFile }) => {
    //     // 데이터가 이미 필터링된 상태이므로, 정렬만 수행
    //     const sortedData = dataCollected.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));

    const DataTable = ({ dataCollected = [], dataCollectHeader, dataCollectHeaderWidths, profileID, downloadFile }) => {
        // dataCollected가 undefined일 경우 빈 배열로 설정하여 sort 에러 방지
        const sortedData = dataCollected.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
        
        return (
            <div>
                {sortedData.length > 0 ? (
                    <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ border: '0.5px solid black' }}>
                                {dataCollectHeader.map((header, index) => (
                                    <th key={header} style={{ width: `${dataCollectHeaderWidths[index]}px`, border: '0.5px solid black', backgroundColor: 'lightgrey', padding: '5px'}}>
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, index) => (
                                <TableRow key={index} item={item} downloadFile={downloadFile} />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            height: '200px',
                            border: '1px solid black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto',
                            fontSize: '1.5em',
                            borderRadius: '10px',
                            padding: '3px',
                            color: 'grey',
                            gap: '10px'
                        }}
                    >
                        <div>👀 데이터워너원 이용 이력이 없어요</div>
                        <Button
                            size="sm"
                            style={{ backgroundColor: mainColor }}
                            onClick={() => { history.push('/') }}
                        >
                            Data 수집/분석하러 가기
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const showDataCollectList = () => {
        // 데이터가 이미 필터링된 상태이므로, 정렬만 수행
        // const sortedData = dataCollected.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
        const sortedData = (dataCollected || []).sort((a, b) => new Date(b.request_date) - new Date(a.request_date));

        return (
            <div>
                <DataTable 
                    dataCollected={sortedData} 
                    dataCollectHeader={dataCollectHeader} 
                    dataCollectHeaderWidths={dataCollectHeaderWidths}
                    profileID={profileID} 
                    downloadFile={downloadFile} 
                />
            </div>
        );
    };


    // // sortedData 도 적용
    // const showDataCollectList = () => {
    //     // profileID와 일치하는 데이터만 필터링
    //     const filteredData = dataCollected.filter(item => item.profile_id == profileID);
    //     // request_date를 기준으로 최신순으로 정렬
    //     const sortedData = filteredData.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
    //     console.log(`dataCollected.length 리스트 : ${dataCollected.length}`);

    //     return (
    //         <div>
    //             {filteredData.length > 0

    //                 ?
    //                 <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
    //                     <thead>
    //                         <tr style={{ border: '0.5px solid black' }}>
    //                             {dataCollectHeader.map((header, index) => (
    //                                 <th key={header} style={{ width: `${dataCollectHeaderWidths[index]}px`, border: '0.5px solid black', backgroundColor: 'lightgrey', padding: '5px'}}>
    //                                     {header}
    //                                 </th>
    //                             ))}
    //                         </tr>
    //                     </thead>

    //                     <tbody>
    //                         {sortedData.map((item, index) => (
    //                             <tr key={index}>
    //                                 <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{formatDate(item.request_date)}</td>
    //                                 {/* <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.profile_id}</td> */}
    //                                 {/* <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.transaction_no}</td> */}
    //                                 <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{dataCollectedTextReplace(item.service_code, "service_code", "")}</td>
    //                                 <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.product_code}</td>
    //                                 <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{new Intl.NumberFormat('en-US').format(item.request_cnt)}</td>
    //                                 <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{dataCollectedTextReplace(item.complete_code, "complete_code", "")}</td>
    //                                 <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
    //                                     {handleLink(item)}
    //                                 </td>
    //                                 <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
    //                                     <button
    //                                         style={{
    //                                             backgroundColor: 'white',
    //                                             border: 'None',
    //                                             cursor: 'pointer',
    //                                             color: 'blue',
    //                                             padding: '5px'
    //                                         }}
    //                                         onClick={() => { downloadFile(item.service_code, item.product_code, item.request_cnt) }}
    //                                     >
    //                                         Download
    //                                     </button>
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                 </table>
    //                 :
    //                 <div
    //         style={{
    //             width: '100%',
    //             height: '200px',
    //             border: '1px solid black',
    //             display: 'flex',
    //             flexDirection: 'column', // 수직 배치
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             margin: '0 auto',
    //             fontSize: '1.5em',
    //             borderRadius: '10px', // 둥근 모서리 추가
    //             padding: '3px', // 내부 여백 추가
    //             color: 'grey',
    //             gap: '10px' // 요소 간의 간격 추가
    //         }}
    //     >
    //         <div>👀 데이터워너원 이용 이력이 없어요</div>
    //         <Button
    //             size="sm"
    //             backgroundColor={mainColor}
    //             onClick={() => { history.push('/') }}
    //         >
    //             Data 수집/분석하러 가기
    //         </Button>
    //     </div>                }
    //         </div>
    //     )
    // }


    const showPaymentList = () => {
        // 데이터가 이미 필터링된 상태이므로, 정렬만 수행
        const sortedPaymentList = (paymentList || []).sort((a, b) => new Date(b.payment_date) - new Date(a.payment_date));
        return (
            <div>
                {sortedPaymentList.length > 0
                ? // 이력 있는 경우
                <table style={{ tableLayout: 'fixed', width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr style={{ border: '0.5px solid black' }}>
                            {paymentHeader.map((header, index) => (
                                <th key={header} style={{ width: `${paymentListHeaderWidths[index]}px`, border: '0.5px solid black', backgroundColor: 'lightgrey', padding: '5px' }}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                        <tbody>
                            {sortedPaymentList.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{formatDate(item.payment_date)}</td>
                                    {/* <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.profile_id}</td> */}
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.transaction_no}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{dataCollectedTextReplace(item.service_code, "상품종류", "")}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{item.product_name}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{new Intl.NumberFormat('en-US').format(item.request_cnt)}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>{new Intl.NumberFormat('en-US').format(item.payment_amount)}</td>
                                    <td style={{ textAlign: 'center', border: '0.5px solid black' }}>
                                    <a href={dataCollectedTextReplace(item.product_code, "링크", item.service_code)} target='_blank' style={{ color: 'blue', textDecoration: 'underline', padding: '5px', display: 'inline-block' }}>링크</a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                </table>
                : // 이력 없는 경우

                <div
            style={{
                width: '100%',
                height: '200px',
                border: '1px solid black',
                display: 'flex',
                flexDirection: 'column', // 수직 배치
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                fontSize: '1.5em',
                borderRadius: '10px', // 둥근 모서리 추가
                padding: '3px', // 내부 여백 추가
                color: 'grey',
                gap: '10px' // 요소 간의 간격 추가
            }}
        >
            <div>💸 결제 이력이 없어요</div>
        </div>


            }
            </div>
        );
    };

    return (
        <div>
            {logoutTracked && <EventTracker eventParams={logoutEventParams} />} {/* 로그아웃 이벤트 트래커 */}
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <h1>마이 페이지</h1>
                <br />
                <button
                    style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'grey',
                        cursor: 'pointer',
                        fontSize: '16px',
                        padding: '0',
                        textDecoration: 'underline',
                    }}
                    onClick={handleLogout}
                >
                    로그아웃
                </button>
                <button
                    style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'red',
                        cursor: 'pointer',
                        fontSize: '16px',
                        padding: '0',
                        textDecoration: 'underline',
                    }}
                    onClick={handleDeleteAccount}
                >
                    회원탈퇴
                </button>
            </div>


            {/* <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <h1>마이 페이지</h1>
                <br />
                <button
                    style={{
                        background: 'none',
                        border: 'none',
                        boxShadow: 'none',
                        color: 'grey',
                        cursor: 'pointer',
                        fontSize: '16px',
                        padding: '0',
                        textDecoration: 'underline'
                    }}
                    onClick={() => handleLogout()}
                >
                    로그아웃
                </button>
            </div> */}

            <br /><br /><br /><br /><br />

            <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <h2>👨‍💻 Data 수집/분석 이력</h2>
                <InfoTooltip text="이력이 정상 조회 안 될 경우 새로고침 해보세요!" />
                </div>
                <br /><br />
                {showDataCollectList()}

            </div>

            <br /><br /><br />

            <div>
                <h2>💰 결제내역</h2>
                <br /><br />
                {showPaymentList()}
            </div>
        </div>
    );
};

export default Mypage;
