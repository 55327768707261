import React, { useEffect, useState } from 'react';
import axios from 'axios';
import KakaoLogin from 'react-kakao-login';
import kakaoLoginBtn from '../assets/images/kakao_login_medium_narrow.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LoginHandeler from './LoginHandeler';
import { useHistory } from 'react-router-dom';  // React Router DOM에서 useHistory import
import Button from '../components/Button';
import { toBeDisabled } from '@testing-library/jest-dom/matchers';
import { kakaoJSkey } from '../config';
import kakaoButtonImage from '../assets/images/kakao_login_medium_narrow.png';
import Swal from 'sweetalert2'; // SweetAlert2 임포트

const Login = () => {
    const history = useHistory();  // useHistory 훅 초기화
    const [kakaoInitialized, setKakaoInitialized] = useState(false);
    const [profileID, setProfileID] = useState('');
    const customButtonStyle = {
        backgroundColor: '#FEE500', // 카카오 노란색
        border: 'none',
        borderRadius: '5px',
        color: '#3C1E1E', // 카카오 로고 텍스트 컬러
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    useEffect(() => {
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');
        const kakaoScript = document.createElement('script');
        kakaoScript.src = 'https://developers.kakao.com/sdk/js/kakao.js';
        kakaoScript.async = true;
        kakaoScript.onload = () => {
            if (window.Kakao) {
                window.Kakao.init(kakaoJSkey);
                setKakaoInitialized(true); // Kakao SDK 초기화 완료
            }
        };
        document.body.appendChild(kakaoScript);

    }, [kakaoJSkey]);
    console.log(`profileID: ${profileID}`);
    

    // 로그인 성공 시 /loginloading 페이지로 이동
    const responseKakao = (response) => {
        console.log(response);
        history.push('/loginhandeler', { response: response});  
    };

    // 로그인 오류 시
    const responseFail = (err) => {
        console.error(err);
    };

    // 로그아웃 시
    const handleLogout = () => {
        console.log(`handleLogout! profileID: ${profileID}`);
        // if (kakaoInitialized && window.Kakao.Auth.getAccessToken()) {
        if (profileID) {
            window.Kakao.Auth.logout(function () {
                console.log('카카오 로그아웃 성공');
                localStorage.setItem('profileID', '');
                history.push('/');
            });
        }
    };

    return (
        
        <div style={{display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center'}}>
            <br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/>
            <KakaoLogin
                token={kakaoJSkey}
                onSuccess={responseKakao}
                onFailure={responseFail}
                getProfile={true}
                useDefaultStyle={false} // 기본 스타일 사용
                render={({ onClick }) => (
                    <img 
                        src={kakaoButtonImage}
                        alt="Kakao Login" 
                        onClick={onClick}
                        style={{ 
                            cursor: 'pointer', 
                            width: '200px', // 이미지 크기를 조정할 수 있습니다.
                            height: 'auto'
                        }}
                    />
                )}
                popup={true} // 팝업 로그인 창 사용
            />
            {/* <Button onClick={()=>handleLogout()} disabled={!profileID}>로그아웃</Button> */}
        </div>
    );
};

export default Login;
