import React, { useContext, useEffect, useState } from 'react';
// import { tableVisitInfo, saveDataToDynamoDB } from '../config';
import { rdswrite } from '../config';
import { getCurrentDateTime } from '../utils/getCurrentDateTime'; // 현재 시간 읽어오기
import { isMobile, isDesktop } from '../utils/detectDevice'; // 디바이스 타입 읽어오기
import axios from 'axios';

const PageTracker = ({ pageName, pageCode }) => {
  const [isKeyLoaded, setIsKeyLoaded] = useState(false);
  let cateUid = "";
  let uid = "";
  let deviceType = "";

  //사용자ID
  const storedProfileID = localStorage.getItem('profileID');
  //로그인 여부 기반 uid, cateUid
  if (storedProfileID === "") {
    cateUid = 'session';
    uid = 'session_test';
  } else {
    cateUid = 'profile';
    uid = storedProfileID;
  }

  // 방문시간
  const visitTime = getCurrentDateTime();

  // 디바이스 타입 
  if (isMobile()) deviceType = 'mw';
  else if (isDesktop()) deviceType = 'web';

  // 방문값 누적 적재용 (PK)
  const uniVisitCode = uid + "_" + pageCode + "_" + visitTime;

  // DB 저장용 배열 세팅
  const items = {
    "uni_visit_code": uniVisitCode,
    "page_name": pageName,
    "page_code": pageCode,
    "uid": uid,
    "cate_uid": cateUid,
    "visit_time": visitTime,
    "device_type": deviceType,
  }

  console.log("uniVisitCode:", items.uniVisitCode);
  console.log("page_name:", items.pageName);
  console.log("page_code:", items.pageCode);
  console.log("uid:", items.uid);
  console.log("cate_uid:", items.cateUid);
  console.log("visit_time:", items.visitTime);
  console.log("device_type:", items.deviceType);


  // saveDataToDynamoDB(tableVisitInfo, items);

  // const saveData = async () => {
  //   try {
  //     const data = await saveDataToDynamoDB(tableVisitInfo, items);
  //     console.log('Data saved:', data);
  //   } catch (error) {
  //     console.error('Error saving data:', error);
  //   }
  // };

  // saveData();

  // RDS에 저장할 컬럼명
  const columns = [
    "uni_visit_code", "page_name", "page_code", "uid",
    "cate_uid", "visit_time", "device_type"
  ];

  // Lambda에 데이터 전송 함수
  const saveDataToRDS = async () => {
    try {
      const response = await axios.post(rdswrite, {
        table_name: "tb_visit_info", // 삽입할 테이블 이름
        columns: columns,         // 테이블 컬럼명
        values: [Object.values(items)] // 삽입할 데이터 배열
      });
      console.log('Data saved to RDS tb_visit_info:', response.data);
    } catch (error) {
      console.error('Error saving data to RDS tb_visit_info:', error);
    }
  };

useEffect(() => {
  if (rdswrite) {
    setIsKeyLoaded(true);
  } else {
    console.log("rdswrite 키를 받아오지 못했습니다. 재시도 중...");
    // 주기적으로 키를 다시 확인하는 로직
    const retryInterval = setInterval(() => {
      if (rdswrite) {
        setIsKeyLoaded(true);
        clearInterval(retryInterval); // rdswrite가 로딩되면 interval 제거
      }
    }, 2000); // 2초마다 rdswrite 확인

    return () => clearInterval(retryInterval); // 컴포넌트가 언마운트되면 정리
  }
}, [rdswrite]);

useEffect(() => {
  if (isKeyLoaded) {
    saveDataToRDS();
  }
}, [isKeyLoaded]);

  return null;
};

export default PageTracker;