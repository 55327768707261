import { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { TOSS_PAYMENT_LIVE_KEY, rdswrite } from '../config';
import Button from '../components/Button';

import { tossClientKeySecretLive, mainColor } from '../config';

const PaySuccess = () => {
    const history = useHistory();  // useHistory 훅 초기화
    const location = useLocation();
    const [isKeyLoaded, setIsKeyLoaded] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const productName = queryParams.get("productName");
    const productCode = queryParams.get("productCode");
    const serviceCode = queryParams.get("serviceCode");
    const paymentDate = queryParams.get("paymentDate");
    const profileID = queryParams.get("profileID");
    const transactionNo = queryParams.get("orderId");
    const estimatedCost = queryParams.get("amount");
    const requestCNT = queryParams.get("requestCNT")


    console.log(`orderId=${transactionNo}&amount=${estimatedCost}&profileID=${profileID}&serviceCode=${serviceCode}&productName=${"수집한서비스명"}&productCode=${productCode}&paymentDate=${paymentDate}&requestCNT=${requestCNT}`);
    

    // DB 저장용 배열 세팅
    const items = {
        "transaction_no"  : transactionNo,
        "service_code"   : serviceCode,
        "product_name"   : productName,   // 영상제목 or 앱 이름
        "product_code"   : productCode,   // 영상/앱 URL 고유 Key 값
        "payment_date"   : paymentDate,   // 결제일+시간 timestamp
        "payment_amount" : estimatedCost, // 결제금액
        "profile_id"     : profileID,     // 사용자ID
        "request_cnt"    : requestCNT     // 데이터 수집 요청 건수
    }

    // RDS에 저장할 컬럼명
    const columns = [
        "transaction_no",
        "service_code",
        "product_name",
        "product_code",
        "payment_date",
        "payment_amount",
        "profile_id",
        "request_cnt"
    ];

    const TableNamePayment = "tb_payment";

    // Lambda에 데이터 전송 함수
    const saveDataToRDS = async () => {
        try {
            const response = await axios.post(rdswrite, {
                table_name: TableNamePayment, // 삽입할 테이블 이름
                columns: columns,         // 테이블 컬럼명
                values: [Object.values(items)] // 삽입할 데이터 배열
            });
            console.log(`Data saved to RDS ${TableNamePayment}: ${response.data}`);
        } catch (error) {
            console.error(`Error saving data to RDS ${TableNamePayment}: ${error}`);
        }
    };

    const paymentConfirm = async () => {
        const base64EncodedKey = window.btoa(tossClientKeySecretLive + ':');
        console.log('paysucess 페이지 접근!');
        const requestData = {
            orderId: queryParams.get("orderId"),
            amount: queryParams.get("amount"),
            paymentKey: queryParams.get("paymentKey"),
        };

        await axios.post('https://api.tosspayments.com/v1/payments/confirm', requestData,
            {
                headers: {
                    'Authorization': `Basic ${base64EncodedKey}`,
                    'Content-Type': 'application/json', // JSON 데이터로 전송
                },
            }
        ).then((res) => {
            console.log(`성공 : ${res}`);
            saveDataToRDS();
        }).catch((err) => {
            console.log(`에러 : ${err}`);
        })
    }


    useEffect(() => {
        if (tossClientKeySecretLive) {
            setIsKeyLoaded(true);
        } else {
            // Refresh the page if the key is not available
            window.location.reload();
        }
        
    }, [tossClientKeySecretLive]);

    useEffect(() => {
        if (isKeyLoaded) {
            paymentConfirm();
        }
    }, [isKeyLoaded]);
    

    return (

        <div className="result wrapper" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            fontSize: '1.5em',
        }}>
            <div className="box_section">
                {isKeyLoaded ? <h2>✅ 결제 성공!</h2> : <h2>💬 결제 진행 중</h2>}
                
                <br />
                <p>{`주문번호: ${queryParams.get("orderId")}`}</p>
                <p>{`결제 금액: ${Number(
                    queryParams.get("amount")
                ).toLocaleString()}원`}</p>
                <p>{`paymentKey: ${queryParams.get("paymentKey")}`}</p>

                <br /><br /><br />
                <p>마이페이지에서 데이터를 다운받을 수 있어요 :)</p>
                <br />
                <Button
                    size="sm"
                    backgroundColor={mainColor}
                    style={{
                        height: '30px',
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '3px',
                        cursor: 'pointer',
                    }}
                    onClick={() => history.push('/mypage')}
                >
                    마이페이지 이동하기
                </Button>
            </div>
        </div>
    );
};

export default PaySuccess;
