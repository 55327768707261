import { useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { tossClientKeySecretLive } from '../config';
import Button from '../components/Button'

const PayFail = () => {
    const history = useHistory();  // useHistory 훅 초기화
    const mainColor = 'rgb(70, 100, 166)';
    
    useEffect(() => {
        
        
    }, []);

    const confirm = () => {
        // const response = 
    }


    return (

        <div className="result wrapper" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            fontSize: '1.5em',
        }}>
            <div className="box_section">
                <h2>
                    ❌ 결제 실패
                </h2>
                <br/>
                <h3>카카오톡 플러스친구 '데이터101'으로 문의해 주세요.</h3>

                <Button
                    size="sm"
                    backgroundColor={mainColor}
                    style={{
                        height: '30px',
                        color: '#fff',
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '3px',
                        cursor: 'pointer',
                    }}
                    onClick={()=>history.push('/')}
                >
                    홈페이지 이동
                </Button>
            </div>
        </div>
    );
};

export default PayFail;
