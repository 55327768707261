import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';

import { addItem } from '../redux/shopping-cart/cartItemsSlide';
import { remove } from '../redux/product-modal/productModalSlice';

import Button from './Button';
import numberWithCommas from '../utils/numberWithCommas';
import Swal from 'sweetalert2';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

import loadingDefault from '../assets/images/loading_default.gif';
import '../sass/FileUpload.css'
import visImgSample from '../assets/images/vis_example_240707.png';


import { sentimentClassifierAPI, mainColor, UploadFileAPI } from '../config';

const ProductViewAI = (props) => {
    const disabledBtnColor = 'grey'
    const dispatch = useDispatch();

    let product = props.product || {
        title: "",
        price: '',
        image01: null,
        image02: null,
        categorySlug: "",
        colors: [],
        slug: "",
        size: [],
        description: "",
        descriptionTag: "",
        dataCrawlingType: [],
        urlInputGuideText: "",
        urlInputGuideTextSub: "",
    };

    const [previewImg, setPreviewImg] = useState(product.imageDetail);
    const [descriptionExpand, setDescriptionExpand] = useState(false);
    const [color, setColor] = useState(undefined);
    const [size, setSize] = useState(undefined);
    const [quantity, setQuantity] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploadBtnDisabled, setFileUploadBtnDisabled] = useState(false);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState(false);
    const [data, setData] = useState([]);
    const [dataUploadFlag, setDataUploadFlag] = useState(false);

    const [uploadFileHeaders, setUploadFileHeaders] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [fileValidation, setFileValidation] = useState(false);

    //----------------------파일/시각화 버튼 컬러----------------------//
    const [fileUploadBtnColor, setFileUploadBtnColor] = useState(disabledBtnColor);
    //------------------------------------------------------------//

    //-------------------output img 할당변수-------------------//
    const [imgClassification, setImgClassification] = useState(null);
    //-------------------------------------------------------//

    const [classificationBtnIsClicked, setClassificationBtnIsClicked] = useState(false);

    const [loadingClassification, setLoadingClassification] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [loadingAPIcomplete, setLoadingAPIcomplete] = useState(false);
    const [profileID, setProfileID] = useState('');
    const history = useHistory();  // useHistory 훅 초기화

    const [transactionNo, setTransactionNo] = useState("test");
    const [productCode, setProductCode] = useState('test');
    const [serviceCode, setServiceCode] = useState('test');

    useEffect(() => {
        setPreviewImg(product.imageDetail);
        const storedProfileID = localStorage.getItem('profileID');
        setProfileID(storedProfileID || '');

        if (loadingClassification) {
            const timer = setTimeout(() => {
                setLoadingClassification(false);
                setLoadingComplete(true);
            }, 10000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [product, loadingClassification, sentimentClassifierAPI]);
    console.log(`profileID: ${profileID}`);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([1, 2, 3, 4, 5]);

    const checkboxTextData = [
        { id: 1, text: '리뷰 내용' },
        { id: 2, text: '리뷰 평점' },
        { id: 3, text: '리뷰 등록일' },
        { id: 4, text: '작성자명' },
        { id: 5, text: '좋아요 개수' },
    ];

    const optionPrices = {
        option1: 10000,
        option2: 15000,
        option3: 20000,
        option4: 25000,
        option5: 30000,
    };

    const [selectedOption, setSelectedOption] = useState('option1');
    const [estimatedCost, setEstimatedCost] = useState(optionPrices.option1);

    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue);
        if (selectedValue && optionPrices[selectedValue] !== undefined) {
            const cost = optionPrices[selectedValue];
            setEstimatedCost(cost);
        } else {
            setEstimatedCost(0);
        }
    };

    ///////////////// 불용어 등록 ///////////////////
    const [queue1, setQueue1] = useState([]);
    const [queue2, setQueue2] = useState([]);
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');

    const handleChange1 = (e) => {
        setInput1(e.target.value);
    };
    const handleKeyPress1 = (e) => {
        if (e.key === 'Enter' && input1.trim() !== '') {
            setQueue1([input1, ...queue1]);
            setInput1('');
        }
    };
    const handleRemove1 = (index) => {
        setQueue1(queue1.filter((_, i) => i !== index));
    };
    const handleChange2 = (e) => {
        setInput2(e.target.value);
    };
    const handleKeyPress2 = (e) => {
        if (e.key === 'Enter' && input2.trim() !== '') {
            setQueue2([input2, ...queue2]);
            setInput2('');
        }
    };
    const handleRemove2 = (index) => {
        setQueue2(queue2.filter((_, i) => i !== index));
    };
    ////////////////////////////////////////////////////


    const uploadFileS3 = () => {

        try {

            // 파일 미선택 시 경고 팝업
            if (!fileUploadedSuccessfully) {
                Swal.fire({
                    icon: 'warning',
                    title: 'csv, xlsx 파일을 업로드해 주세요',
                    confirmButtonText: '확인',
                });
                return;
            }
            // 파일 용량 제한 초과 시
            if (selectedFile.size > 2.5e+6) {
                console.log(`업로드 파일 업로드 용량 초과 : ${selectedFile.size}`);
                Swal.fire({
                    icon: 'error',
                    title: '최대 용량 초과',
                    html: '2.5MB 이하의 csv, xlsx 파일만 업로드해 주세요.',
                    confirmButtonText: '확인',
                });
                return;

            }

            if (selectedFile.size <= 2.5e+6) { }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    html: 'csv, xlsx, xls 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                    confirmButtonText: '확인',
                });
                return;
            }
            const fileType = selectedFile.name.split('.').pop();
            // dataPreviewSetup(fileType);
            const reader = new FileReader();
            const fileExtension = selectedFile.name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: '현재 csv, xlsx 파일만 업로드 가능합니다.',
                    confirmButtonText: '확인',
                });
                setSelectedFile(null);
                return;
            } else {
                console.log('유효한 파일 형식');

                setFileValidation(true);
                setFileUploadedSuccessfully(true);
                setFileUploadBtnColor(disabledBtnColor);
                reader.onload = (e) => {
                    const fileData = e.target.result;
                    const generateUniqueHeader = (headers) => {
                    
                        console.log(`-----generateUniqueHeader 함수 실행-----`);
                        console.log(`headers.length : ${headers.length}`);
                        console.log(`headers[0] : ${headers[0]}`);
                        console.log(`type(headers[0]) : ${typeof (headers[0])}`);
                        console.log(`headers[1] : ${headers[1]}`);
                        console.log(`headers 자체 : ${headers}`);
                        // const newHeaders = headers.map(item => typeof(item) === undefined ? "undefinedColumn" : item);
                        const newHeaders = Array.from(headers, item => {
                            if (typeof item === 'undefined') {
                                return "undefinedColumn";
                            } else {
                                return item;
                            }
                        })

                        const uniqueHeaders = [];
                        const headerCount = {};
                        console.log(`newHeaders[0] : ${newHeaders[0]}`);
                        console.log(`type(newHeaders[0]) : ${typeof (newHeaders[0])}`);
                        console.log(`newHeaders 자체 : ${newHeaders}`);

                        newHeaders.forEach((header, index) => {
                            console.log(`현재 header : ${header}`);

                            if (header === "" || header === "undefined") {
                                console.log(`현재 header 비어있거나 undefinde : ${header}`);
                                header = "undefinedColumn";
                            }

                            if (uniqueHeaders.includes(header)) {
                                let count = headerCount[header] || 1;
                                let newHeader = `${header}${count}`;

                                while (uniqueHeaders.includes(newHeader)) {
                                    count += 1;
                                    newHeader = `${header}${count}`;
                                }

                                uniqueHeaders.push(newHeader);
                                headerCount[header] = count + 1;
                            } else {
                                uniqueHeaders.push(header);
                                headerCount[header] = 1;
                            }
                        });

                        return uniqueHeaders;
                    };

                    if (fileExtension === 'csv') {
                        Papa.parse(fileData, {
                            header: true,
                            complete: (results) => {
                                const csvData = results.data;
                                console.log(`csvData: ${csvData}`);
                                let headers = Object.keys(csvData[0]);
                                console.log(`csvData[1]: ${Object.keys(csvData[1])}`);
                                headers = generateUniqueHeader(headers);
                                console.log(`headers: ${headers}`);
                                console.log(`headers type: ${typeof (headers)}`);
                                // 처음 5개의 행만 선택
                                const rows = csvData.slice(0, 5).map(Object.values);
                                console.log(`rows: ${rows}`);
                                setData([headers, ...rows]);
                            },
                        });
                    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                        const workbook = XLSX.read(fileData, { type: 'binary' });
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        let headers = jsonData[0];
                        console.log(`jsonData[0]: ${jsonData[0]}`);

                        headers = generateUniqueHeader(headers);
                        jsonData[0] = headers;
                        // 헤더의 일부 셀이 비어 있거나 "undefined"면 unique header 생성
                        if (jsonData.length > 0) {

                        }
                        setData(jsonData.slice(0, 5));
                    }
                };

                if (fileExtension === 'csv') {
                    reader.readAsText(selectedFile);
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    reader.readAsArrayBuffer(selectedFile);  // XLSX 파일은 ArrayBuffer로 읽음
                }


                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('profileID', profileID);

                try {
                    const response = axios.post(UploadFileAPI, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(() => {
                        console.log('File uploaded successfully:', response.data);
                        setFileUploadedSuccessfully(true);
                        Swal.fire({
                            icon: 'success',
                            title: '파일 업로드 성공',
                            text: `* 파일명 : ${selectedFile.name}`,
                            confirmButtonText: '확인',
                        });
                        setDataUploadFlag(true);
                    });
                    
                } catch (error) {
                    console.error('Error uploading file:', error);
                    Swal.fire({
                        icon: 'error',
                        title: '파일 업로드 실패',
                        html: 'csv, xlsx, xls 파일만 업로드해 주세요.<br/>문제 해결이 안 되면 카톡플러스친구로 문의바랍니다.',
                        confirmButtonText: '확인',
                    });
                }

            }
        } catch (error) {
            console.log(`파일 업로드 중 에러 발생 : ${error}`);
        }
    }


    const onFileChange = (event) => {
        try {
            console.log(`event.target.files[0]: ${event.target.files[0]}`);
            setSelectedFile(event.target.files[0]);
            const reader = new FileReader();
            const fileExtension = event.target.files[0].name.split('.').pop();
            console.log(`fileExtension: ${fileExtension}`);
            // if (fileExtension != 'csv' && fileExtension != 'xlsx' && fileExtension != 'xls') {
            if (fileExtension != 'csv') {
                console.log(`업로드 파일 형식 미지원`);
                setFileValidation(false);
                Swal.fire({
                    icon: 'error',
                    title: '파일 업로드 실패',
                    text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                    confirmButtonText: '확인',
                });
                setSelectedFile(null);
                return;
            } else {
                // 유효한 파일 확인 case
                setFileUploadBtnColor(mainColor);
                setFileUploadedSuccessfully(true);
                // dataPreviewSetup(fileExtension);
            };

        } catch (err) {
            console.log(`error 발생: ${err}`);
        }
    };

    const calculateColumnWidths = () => {
        if (data.length === 0) return [];
        const columnWidths = data[0].map((_, colIndex) => {
            const maxLength = Math.max(
                ...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
            );
            return maxLength * 9; // Adjust the multiplier as needed
        });
        return columnWidths;
    };

    const dataPreviewSetup = (fileExtension) => {
        const reader = new FileReader();
        console.log(`fileExtension: ${fileExtension}`);
        if (fileExtension != 'csv') {
            console.log(`업로드 파일 형식 미지원`);
            setFileValidation(false);
            Swal.fire({
                icon: 'error',
                title: '파일 업로드 실패',
                text: 'csv 파일만 업로드 가능합니다. 변환후 업로드 해 주세요.',
                confirmButtonText: '확인',
            });
            setSelectedFile(null);

            return;
        } else {
            console.log('유효한 파일 형식');
            setFileValidation(true);
            setFileUploadedSuccessfully(true);
            setFileUploadBtnColor(disabledBtnColor);
            reader.onload = (e) => {
                const fileData = e.target.result;
                if (fileExtension === 'csv') {
                    Papa.parse(fileData, {
                        header: true,
                        complete: (results) => {
                            const csvData = results.data;
                            console.log(`csvData: ${csvData}`);
                            const headers = Object.keys(csvData[0]);
                            console.log(`headers: ${headers}`);
                            console.log(`headers type: ${typeof (headers)}`);
                            const rows = csvData.map(Object.values);
                            console.log(`rows: ${rows}`);
                            setData([headers, ...rows]);
                        },
                    });
                } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    const workbook = XLSX.read(fileData, { type: 'binary' });
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1

                    });
                    setData(jsonData.slice(0, 10));

                }
            };
            if (fileExtension === 'csv') {
                console.log("fileExtension === 'csv'");
                reader.readAsText(selectedFile);
            } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                console.log("fileExtension === 'xlsx' || fileExtension === 'xls'");
                reader.readAsBinaryString(selectedFile);
            }

        }

    }

    const dataPreviewShow = () => {
        const columnWidths = calculateColumnWidths();
        return dataUploadFlag ? (
            <div>
                <br />
                <br />
                <table style={{ borderCollapse: 'collapse', border: '1px solid black' }}>
                    <thead>
                        <tr>
                            {data[0] &&
                                data[0].map((header, index) => (
                                    <th key={index} style={{ width: columnWidths[index] + 20, border: '1px solid black', padding: '8px' }}>
                                        {header}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(1).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ width: columnWidths[cellIndex] + 20, border: '1px solid black', padding: '8px' }}>
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <div
                style={{
                    width: '100%',
                    height: '200px',
                    border: '1px solid black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    fontSize: '1.5em',
                    borderRadius: '10px', // 둥근 모서리 추가
                    padding: '3px', // 내부 여백 추가
                    color: 'grey',
                }}
            >
                파일 업로드 시 일부 Data가 미리 보여집니다.
            </div>
        );
    };

    const handleColumnChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    const selectColumn = () => {
        return dataUploadFlag ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <select value={selectedColumn} onChange={handleColumnChange}>
                    <option value="" disabled>컬럼(column) 선택</option>
                    {data[0].map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <Button
                    size="sm"
                    disabled={!selectedColumn}
                    backgroundColor={mainColor}
                    onClick={onClassification}
                >
                    시각화하기
                </Button>
            </div>
        )

            :
            (
                null
            )
    }

    const saveVisImg = () => {
        let imageDataUrl = "";
        let filename = "";
        imageDataUrl = imgClassification; filename = "data101_classification.png";

        try {
            // Base64 데이터 부분 추출
            const base64Data = imageDataUrl.split(',')[1];
            const mimeString = imageDataUrl.split(',')[0].split(':')[1].split(';')[0];

            // Base64 데이터를 Blob으로 변환
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeString });

            // Blob을 Object URL로 변환
            const url = URL.createObjectURL(blob);

            // 다운로드 링크 생성 및 클릭 이벤트 트리거
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Object URL 해제
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error during image download:', error);
        }
    }

    const showImgDownBtn = () => {
        return (
            <div style={{ paddingLeft: '15px' }}><Button
                size="sm"
                disabled={!loadingComplete}
                backgroundColor={(!loadingComplete) ? 'grey' : mainColor}
                // disabled={(!imgKeywordFreq & !imgWordCloud & !imgWordNetwrok)}
                // backgroundColor={(!imgKeywordFreq & !imgWordCloud & !imgWordNetwrok) ? 'grey' : mainColor}
                onClick={() => saveVisImg()}
            >다운로드</Button></div>
        )
    }

    const showClassification = () => {
        return (
            <div>
                <div
                    style={{
                        width: '100%',
                        height: '10px',
                        display: 'flex',
                    }}
                >
                    <h2>🤖 AI 감정 분류 결과</h2>
                    {showImgDownBtn()}

                </div>
                <br />
                <br />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',
                                gap: '10px', width : '50%'}}>
                    {handleVisClassification()}
                </div>
            </div >
        )
    }


    // AI 분류 버튼 클릭 시
    const onClassification = () => {
        setLoadingClassification(true);
        console.log(`분류 버튼 클릭!`);
        setClassificationBtnIsClicked(true);
        console.log(`"profileID" : ${profileID},
                    "filename"   : ${selectedFile.name},
                    "selectedColumn" : ${selectedColumn}`)
        try {
            // 분석용 컬럼, profileID, 파일명 전송    
            const req = {
                "profileID": profileID,
                "filename": selectedFile.name,
                "selectedColumn": selectedColumn,
                "transactionNo": transactionNo,
                "service_code": serviceCode,
                "product_code": productCode,
                "stopword":queue1,
                "proper_noun":queue2
            }
            console.log('Request Data:', req); // 요청 데이터 로그 출력
            console.log(`sentimentClassifierAPI : ${sentimentClassifierAPI}`);
            const response = axios.post(sentimentClassifierAPI, req,
                {
                    headers: {
                        'Content-Type': 'application/json', // JSON 데이터로 전송
                    },
                })
                .then((res) => {
                    console.log(`res : ${res.data}`);
                    console.log(`JSON.stringify(res) : ${JSON.stringify(res.data)}`);
                    const resImgClassification = res.data.resImgSentiAnalysis;
                    setImgClassification(`data:image/jpeg;base64,${resImgClassification}`);
                })
                .catch((error) => { 
                    console.log(`Error: ${error}`);
                });


        } catch (error) {
            console.log(`분류 결과 시각화 시 에러 발생 : ${error}`);
            // 요청 실패 시 로딩 상태 변경
            setLoadingClassification(false);
        }
    };

    const handleVisClassification = () => {

        if (!classificationBtnIsClicked) {
            return (
                <img src={visImgSample}>
                </img>
            )
        }

        if (loadingClassification) {
            return (
                <div
                    style={{
                        width: '100%',
                        height: '400px',
                        border: '1px solid black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto',
                        fontSize: '1.5em',
                        borderRadius: '10px', // 둥근 모서리 추가
                        padding: '3px' // 내부 여백 추가
                    }}
                >

                    <img style={{ height: '150px' }} src={loadingDefault} alt="로딩 중..." />
                    <p>데이터 분류를 시작했어요! 최대 15초까지 걸려요 :)</p>
                </div>
            )
        } else if (imgClassification && !loadingClassification) {
            return (
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    marginTop: '20px'
                }}>
                    <img
                        src={imgClassification}
                        alt="Fetched from Lambda"
                    />
                </div>
            )
        };
    }


    return (
        <div>
            <h1>{product.title}</h1>
            <br />
            <div>
                <img src={previewImg} alt="" style={{ height: '300px' }} />
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                <h2>🗂️ 분석할 Data 업로드하기</h2>
            </div>
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                <div className='file-upload'>
                    <input type="file" id="ex_file" onChange={onFileChange} />
                    <div className="button-container">
                        <label htmlFor="ex_file" className="file-upload-button">
                            파일 선택
                        </label>
                        <button
                            // className="file-upload-button"
                            onClick={uploadFileS3}
                            style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                backgroundColor: '#4d7faa',
                                color: 'white',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                fontSize: '16px',
                                textAlign: 'center',
                                border: 'none',
                                // fontFamily: 'NanumSquareR, SCDream2, laundrygothic_Regular, sans-serif'
                                fontFamily: 'SUIT-Regular'

                            }}
                        >
                            업로드하기
                        </button>
                    </div>
                    {selectedFile && (
                        <div>
                            <br />
                            <h3>파일 정보:</h3>
                            <p>
                                <strong>파일 이름:</strong> {selectedFile.name}
                            </p>
                            <p>
                                <strong>파일 크기:</strong> {selectedFile.size} bytes
                            </p>
                            <p>
                                <strong>파일 타입:</strong> {selectedFile.type}
                            </p>
                            <p>
                                <strong>최종 수정 날짜:</strong> {selectedFile.lastModifiedDate.toDateString()}
                            </p>
                        </div>
                    )}
                </div>
                <p style={{ color: 'grey' }}>* 현재 csv 파일 형식만 업로드 지원합니다.</p>
            </div>
            <br />
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <h2>🔍 업로드 Data 미리보기</h2> 
            </div>
            <br/>
            {dataPreviewShow()}
            <br />
            <br />
            <br />
            <br />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <h2>✅ 분석에 사용할 열 선택 (필수사항)</h2>
                {selectColumn()}
            </div>
            <br />
            <p style={{ color: 'grey' }}>* 파일 업로드 후 분석할 열 선택이 가능합니다.</p>
            <br />
            <p style={{ color: 'grey' }}>  반드시 리뷰내용, 댓글 등의 분석이 필요한 칼럼만 선택해 주세요.</p>
            <br /><br /><br />
            <h2>✅ 불용어 등록 (선택사항)</h2>
            <br />
            <div style={{ maxWidth: '600px', margin: '10', padding: '10px 0' }}>
                <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', maxHeight: '150px', overflowY: 'auto', marginBottom: '20px' }}>
                    <h3>등록된 불용어</h3>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {queue1.map((item, index) => (
                            <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f9f9f9', margin: '5px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
                                <span>{item}</span>
                                <button onClick={() => handleRemove1(index)} style={{ background: 'none', border: 'none', color: 'red', fontWeight: 'bold', cursor: 'pointer' }}>X</button>
                            </li>
                        ))}
                    </ul>
                </div>
                <input type="text" value={input1} onChange={handleChange1} onKeyPress={handleKeyPress1} placeholder="Type something and press Enter" style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }} />
            </div>
            <br /><br />
            <h2>✅ 고유명사 등록(선택사항)</h2>
            <br />
            <div style={{ maxWidth: '600px', margin: '10', padding: '10px 0' }}>
                <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px', maxHeight: '150px', overflowY: 'auto', marginBottom: '20px' }}>
                    <h3>등록된 고유명사</h3>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {queue2.map((item, index) => (
                            <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f9f9f9', margin: '5px 0', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}>
                                <span>{item}</span>
                                <button onClick={() => handleRemove2(index)} style={{ background: 'none', border: 'none', color: 'red', fontWeight: 'bold', cursor: 'pointer' }}>X</button>
                            </li>
                        ))}
                    </ul>
                </div>
                <input type="text" value={input2} onChange={handleChange2} onKeyPress={handleKeyPress2} placeholder="Type something and press Enter" style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }} />
            </div>
            <br />
            <br />
            <br />
            <div>{showClassification()}</div>
        </div>
    );
};

ProductViewAI.propTypes = {
    product: PropTypes.object,
};

export default withRouter(ProductViewAI);
